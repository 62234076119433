<template>
  <nav>
    <router-link to="/">История</router-link> |
    <router-link to="/about">О странице</router-link>
  </nav>
</template>

<script>
export default {
  name: "NavigationHeader",
};
</script>

<style scoped>
nav {
  position: fixed;
  background: linear-gradient(#02080d91, #02080d70, transparent);
  padding: 30px 0 50px;
  width: 100%;
  font-size: 150%;
}
</style>
