<template>
  <div>
    <p>
      <b>
        {{ title }}
      </b>
    </p>
    <p class="story-text">
      <span v-for="textElement in text" v-bind:key="textElement">
        <i v-if="textElement.italic === true">{{ textElement.text }}</i>
        <b v-else-if="textElement.bold === true">{{ textElement.text }}</b>
        <p v-else-if="textElement.endline === true" />
        <span v-else>{{ textElement.text }}</span>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: "StoryText",
  props: ["text", "title"],
};
</script>
