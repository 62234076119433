import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import TitleComponent from "./components/VueTitle.vue";

const app = createApp(App);

app.use(router);

app.component("v-title", TitleComponent);

app.mount("#app");
