<template>
  <section class="story-element" :id="storyId">
    <transition name="fade">
      <div v-if="section == storyId + 1">
        <img :src="src" />
        <StoryText :text="textLines" />
      </div>
    </transition>
  </section>
</template>

<script>
import StoryText from "@/components/StoryText.vue";

export default {
  data() {
    return {
      show: true,
      id: 0,
    };
  },
  name: "StoryElement",
  props: ["src", "storyId", "textLines", "section"],
  components: { StoryText },
};
</script>

<style scoped>
.story-element {
  height: 85vh;
  padding-top: 120px;
}

img {
  width: 100%;
  aspect-ratio: 1.25;
  object-fit: cover;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
