<template><title /></template>
<script>
export default {
  name: "v-title",
  props: ["title"],
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title;
      },
    },
  },
};
</script>
