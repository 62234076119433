<template>
  <div>
    <v-title title="App" />
    <NavigationHeader />
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavigationHeader from "@/components/NavigationHeader.vue";

export default {
  name: "App",
  components: {
    NavigationHeader,
  },
};
</script>

<style>
html {
  min-height: 100%;
  height: 100%;
}

body {
  background: #2c3e50;
  margin: 0;
}

body::-webkit-scrollbar {
  display: none;
}

.content {
  padding-top: 100px;
  min-width: 30%;
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
}

#app {
  font-family: Trebuchet MS, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #a8c8e9;
}

p {
  font-size: max(110%, min(1.5vw, 140%));
  text-align: left;
  line-height: 1.6;
}

nav a {
  font-weight: bold;
  color: #a8c8e9;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
