<template>
  <div class="story" v-bind:id="story.id">
    <div class="sections-menu">
      <span
        class="menu-point"
        v-bind:class="{ active: activeSection == index }"
        v-on:click="scrollToSection(index)"
        v-for="(offset, index) in offsets"
        v-bind:key="index"
      />
    </div>
    <section class="story-title">
      <StoryText :title="story.name" :text="story.description" />
    </section>
    <StoryElement
      v-for="(storyFrame, index) in story.frames"
      v-bind:key="index"
      :storyId="index"
      :src="storyFrame.src"
      :textLines="storyFrame.textLines"
      v-bind:section="activeSection"
    />
  </div>
</template>

<script>
import StoryElement from "@/components/StoryElement.vue";
import StoryText from "@/components/StoryText.vue";
import { ref } from "vue";

export default {
  data() {
    return {
      inMove: false,
      activeSection: 0,
      offsets: [],
      touchStartY: 0,
    };
  },
  methods: {
    calculateSectionOffsets() {
      let sections = document.getElementsByTagName("section");
      let length = ref(sections.length);
      for (let i = 0; i < length.value; i++) {
        let sectionOffset = sections[i].offsetTop;
        this.offsets.push(sectionOffset);
      }
    },
    handleMouseWheelDOM: function (e) {
      if (e.deltaY > 0 && !this.inMove) {
        this.moveUp();
      } else if (e.deltaY < 0 && !this.inMove) {
        this.moveDown();
      }
      e.preventDefault();
      return false;
    },
    handleMouseWheel: function (e) {
      if (e.wheelDelta < 30 && !this.inMove) {
        this.moveUp();
      } else if (e.wheelDelta > 30 && !this.inMove) {
        this.moveDown();
      }
      e.preventDefault();
      return false;
    },
    moveDown() {
      this.inMove = true;
      this.activeSection--;

      if (this.activeSection < 0) this.activeSection = this.offsets.length - 1;

      this.scrollToSection(this.activeSection, true);
    },
    moveUp() {
      this.inMove = true;
      this.activeSection++;

      if (this.activeSection > this.offsets.length - 1) this.activeSection = 0;

      this.scrollToSection(this.activeSection, true);
    },
    scrollToSection(id, force = false) {
      if (this.inMove && !force) return false;

      this.activeSection = id;
      this.inMove = true;

      document
        .getElementsByTagName("section")
        [id].scrollIntoView({ behavior: "smooth" });

      setTimeout(() => {
        this.inMove = false;
      }, 400);
    },
    touchStart(e) {
      this.touchStartY = e.touches[0].clientY;
      if (this.touchStartY > 100) e.preventDefault();
    },
    touchMove(e) {
      if (this.inMove) return false;
      const currentY = e.touches[0].clientY;

      if (this.touchStartY < currentY) {
        this.moveDown();
      } else {
        this.moveUp();
      }

      this.touchStartY = 0;
      return false;
    },
  },
  created() {
    setTimeout(this.calculateSectionOffsets, 100);
    setTimeout(this.scrollToSection, 100, 0);

    window.addEventListener("wheel", this.handleMouseWheelDOM, {
      passive: false,
    }); // Mozilla Firefox
    window.addEventListener("mousewheel", this.handleMouseWheel, {
      passive: false,
    }); // Other browsers
    window.addEventListener("touchstart", this.touchStart, {
      passive: false,
    }); // mobile devices
    window.addEventListener("touchmove", this.touchMove, {
      passive: false,
    }); // mobile devices
  },
  unmounted() {
    window.removeEventListener("mousewheel", this.handleMouseWheel, {
      passive: false,
    }); // Other browsers
    window.removeEventListener("wheel", this.handleMouseWheelDOM, {
      passive: false,
    }); // Mozilla Firefox
    window.removeEventListener("touchstart", this.touchStart); // mobile devices
    window.removeEventListener("touchmove", this.touchMove); // mobile devices
  },
  components: {
    StoryElement, //StoryNavigation,
    StoryText,
  },
  props: ["story"],
};
</script>

<style scoped>
.story-title {
  padding-top: 100px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.sections-menu {
  position: fixed;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.sections-menu .menu-point {
  width: 10px;
  height: 10px;
  background-color: #fff;
  display: block;
  margin: 1rem 0;
  opacity: 0.6;
  transition: 0.4s ease all;
}
.sections-menu .menu-point.active {
  opacity: 1;
  transform: scale(1.5);
}
</style>
