<template>
  <div class="story-view">
    <v-title :title="stories[0].name" />
    <StoryComponent :story="stories[0]" />
  </div>
</template>

<script>
import StoryComponent from "@/components/StoryComponent.vue";

export default {
  data() {
    return {
      stories: [
        {
          id: 1,
          name: "Подвальная история",
          description: [
            {
              text: "Здесь для вас откроется невероятная история прямиком из одного камчатского подвала. Все её очевидцы давно мертвы либо признаны пропавшими без вести ",
            },
            { italic: true, text: "(по их собственным заявлениям)" },
            {
              text: ", поэтому сомневаться в достоверности представленных фактов не имеет смысла.",
            },
            { endline: true },
            {
              text: "Описанные события могут вызвать у неподготовленного зрителя паническую атаку, тошноту, эпилепсию, мракобесие, бесоёбство, а также смерть.",
            },
            { endline: true },
            { text: "Чтобы начать погружение, листайте вниз." },
          ],
          frames: [
            {
              src: "https://deadlauncher.ru/static/proper/1.jpg",
              textLines: [
                {
                  text: "Как было сказано, история разворачивается где-то в подвале в районе п-ова Камчатка ",
                },
                {
                  italic: true,
                  text: "(это было определено по данным геолокации на восстановленных снимках).",
                },
                {
                  text: " Предположительно, с владельцами помещения был связан Денис, который и собрал всех участников в подвале.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/2.jpg",
              textLines: [
                {
                  text: "Денис был замечен в связях с организацией «Надлежащий Джент», специализиующейся на изучении психометафизического и гидроакустического влияния на сознание человека. Предположительно, по заказу Джента он собрал всех участников для проведения некоего эксперимента, о чём из присутсвующих знал только сам Денис.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/3.jpg",
              textLines: [
                {
                  text: "По другим данным, в организации эксперимента был также замешан другой участник событий — Женя. Он значительно поспособствовал собиранию всех участников путём уговоров. Кроме того, в процессе эксперимента Женя совершал сомнительные действия, подозрительно направленные против Дениса.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/4.jpg",
              textLines: [
                {
                  text: "Благодаря Денису в эксперимент был втянут Артур, который был стекольщиком и любил стекло. Считается, что наличие этого человека в эксперименте позволило бы оценить гидроакустическое влияние на стеклянные фаллосы и батплаги, находящиеся внутри человека.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/5.jpg",
              textLines: [
                {
                  text: "По инициативе Жени в подвал был приглашён шофёр Федя. Его роль в описываемых событиях наиболее трагична, поскольку Фёдор незаслуженно подвергался одновременному давлению со стороны всех участников эксперимента, а также со стороны атмосферы и артерий.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/6.jpg",
              textLines: [
                {
                  text: "Основной целью сбора была обозначена игра в настольный теннис, что в рамках эксперимента предполагало подготовку сознания участников к гидроакустическому вмешательству посредством звуков «клац-клац». Кроме того, предполагалось за счёт эмоционального давления ослабить психологический иммунитет участников.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/7.jpg",
              textLines: [
                {
                  text: "Спустя час штатной игры в пинг-понг, стали проявляться первые странности. Женя, заканчивая в свою пользу очередную партию с Денисом, решил завершить её эпично, применив запрещённый удар «северного курильщика». Мячик угодил Денису в копчик, он упал на стол и на несколько секунд потерял сознание. Инцидент замялся, но оставил вопросы о намерениях Жени.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/8.jpg",
              textLines: [
                {
                  text: "Далее в эксперимент был введён испытуемый образец некой жидкости, осуществляющей гидроакустическое воздействие на организм. Данная жидкость представлялась и употреблялась как некоторый алкогольный напиток и, предположительно, имела в своём составе спирт (или пиво).",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/9.jpg",
              textLines: [
                {
                  text: "Эффект, производимый образцом на организм, лучше всего видно на Фёдоре. Спустя 30-40 минут после введения, он вызывал тремор, слайдер и бэнгер. Через 50-51 минуту человек уже лежал на земле обездвиженно...",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/10.jpg",
              textLines: [
                {
                  text: "Однако спустя 37-45 секунд приходил в себя и даже начинал говорить. Техника чтения по губам с фотографии позволила установить, что Фёдор говорил что-то вроде «...дайте мне шишигу, и я переверну на ней себя...»",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/10-1.jpg",
              textLines: [
                {
                  text: "После этого состояние Феди улучшилось, однако у него увеличилось внутричерепное давление, что привело к надуванию лба. Подобный эффект связывают с защитными реакциям организма шофёра — надувание подушек безопасности.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/11.jpg",
              textLines: [
                {
                  text: "В той или иной мере, все присутствующие испытали подобные негативные эффекты от препарата. Даже Денис и Женя, в крови которых, предположительно присутствовал антидот. Но лучше всех чувствовал себя Артур, получивший наибольшую дозировку. За ним не было замечено вышеупомянутых негативных эффектов, однако был другой...",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/12.jpg",
              textLines: [
                {
                  text: "Артур стал отдаляться от остальных участников. Достоверно известно, что он не имел никакой информации о проводящемся эксперименте, но при этом, как будто предчувствуя неладное, резко отошёл от остальных. И это было верным решением.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/13.jpg",
              textLines: [
                {
                  text: "В следующие секунды тела оставшейся тройки изогнулись в неестественных позах под действием неведомой силы. Фёдор перешёл в режим энергосбережения, спасая свою любимую бутылку. Женя и Денис будто стали превращаться в единый организм, напоминающий паука с двумя головами, что косвенно подтверждает связь обоих с компанией Джент.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/14.jpg",
              textLines: [
                {
                  text: "Денис предпринял попытку сбежать через теннисную сетку, и в считанные секунды всех отпустило. Удивительным был тот факт, что несмотря на странность происходящего, никого в действительности это не смущало. Объяснить это можно одним из эффектов препарата. Однако Денис всё же решил подстраховаться, инициировав запасной план.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/15.jpg",
              textLines: [
                {
                  text: "План заключался в использовании другого препарата, останавливающего действие первого. Для этого Денис незаметно принёс резервный бутылёк со вторым препаратом, но через некоторое время Фёдор всё же заметил его наличие. Денису пришлось напиздеть, что это крымское вино.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/16.jpg",
              textLines: [
                {
                  text: "Нездоровый интерес к находке проявил Женя, сразу же облюбовав бутыль. По его лицу было видно, что готовится что-то нехорошее. Есть мнение, что Женя знал о подмене второго препарата на более сильную версию первого с целью устранения Дениса. Также существуют предположения, что Женя сам подменил препараты для личной цели — устранения Фёдора.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/17.jpg",
              textLines: [
                {
                  text: "Последняя догадка подтверждается тем фактом, что Женя первым делом решил испытать действие второго препарата на Фёдоре, которого часом ранее неоднократно унизил в игре в теннис ",
                },
                {
                  italic: true,
                  text: "(на самом деле Федю унизили все, но Женя сделал это с особым презрением (на самом деле Женя максимально цинично унизил в теннис всех, но Фёдор всё равно пострадал больше всех)).",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/18.jpg",
              textLines: [
                {
                  text: "Некоторое время после употребления второй препарат не оказывал никакого действия на Фёдора, и все, решив, что он безопасен, принялись принимать его внутрь. Сразу после этого странное действие компонента проявились сразу на всех участниках.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/19.jpg",
              textLines: [
                {
                  text: "Наибольшее воздействие проявилось, ожидаемо, на Фёдоре. У него еще сильнее вздулся лоб. В конце он стал терять свзяь с реальностью и попытался лечь отдохнуть на диван.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/20.jpg",
              textLines: [
                {
                  text: "В тот же момент с ума начал сходить Женя, которого попросили освободить диван, но он в грубой форме отказался со словами «...если я захочу потерять ебало, я его потеряю...»",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/21.jpg",
              textLines: [
                {
                  text: "Артур продержался немного дольше, но тоже стал сдаваться и шалеть.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/22.jpg",
              textLines: [
                {
                  text: "В один момент всех троих стало сильно магнитить головами друг к другу.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/24.jpg",
              textLines: [
                {
                  text: "Свидетелей дальнейших событий найти не удалось. Что произошло в следующие секунды, и где находился Денис — неизвестно. На следующее утро в том же помещении проснулись на одном диване Фёдор и Женя. Дениса и Артура найти не удалось. ",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/25.jpg",
              textLines: [
                {
                  text: "Фёдор с Женей под ручку покинули подвал спустя час после пробуждения и больше их никто не видел. Очевидцы, прибывшие через несколько дней на место событий, обнаружили в мусорке ",
                },
                {
                  italic: true,
                  text: "(на которую указывает Фёдор)",
                },
                {
                  text: " федину бутылку, наполненную жидкостью, по вкусу напоминающей мочу. Предположительно, Фёдор нассал в эту бутылку и оставил в мусорке.",
                },
              ],
            },
            {
              src: "https://deadlauncher.ru/static/proper/26.jpg",
              textLines: [
                {
                  text: "Это всё, что известно о той роковой ночи.",
                },
              ],
            },
          ],
        },
      ],
    };
  },

  name: "StoryView",
  components: { StoryComponent },
};
</script>
